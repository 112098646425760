import React from 'react';
import { TweenMax, TimelineMax, Linear } from 'gsap/TweenMax';

import Button from '../Button';

import './NotFoundView.scss';

class NotFoundView extends React.PureComponent {
  moodRef = React.createRef();

  componentDidMount() {
    new TimelineMax({
      yoyo: true,
      repeat: 99999,
    })
      .call(this.onMoodSelect, ['chill'], this)
      .call(this.onMoodSelect, ['zzzz'], this, '+=3')
      .call(this.onMoodSelect, ['fresh'], this, '+=3')
      .call(this.onMoodSelect, ['lively'], this, '+=3')
      .call(this.onMoodSelect, ['dialed'], this, '+=3');
  }

  onMoodSelect = (mood, transitionDuration = 3) => {
    const gradients = {
      chill:
        'linear-gradient(to top, #e31c79, #f557a1 39%, #f74397 62%, #e31c79)',
      zzzz: 'linear-gradient(to top, #981d97, #ce1ca9 39%, #c438c3 62%, #981d97)',
      fresh:
        'linear-gradient(to top, #f4364c, #ff3f69 39%, #ff3f69 62%, #f4364c)',
      lively:
        'linear-gradient(to top, #ff6534, #ff9323 34%, #ff8200 46%, #ff6534)',
      dialed:
        'linear-gradient(to top, #ffae00, #ffd83a 34%, #ffcd00 71%, #ffbc00)',
    };
    TweenMax.to(this.moodRef.current, transitionDuration, {
      backgroundImage: gradients[mood],
      ease: Linear.ease,
    });
  };

  render() {
    let videoSrc;

    if (window.innerWidth > 1024) {
      videoSrc = '/video/404-desktop.mp4';
    } else {
      videoSrc = '/video/404-mobile.mp4';
    }

    return (
      <div className="NotFoundView" ref={this.moodRef}>
        <video autoPlay playsInline loop muted key={videoSrc}>
          <source
            src={videoSrc}
            type="video/mp4"
            poster="/video/hero-poster.jpg"
          />
          <track kind="captions" />
        </video>
        <div className="NotFoundView--content">
          <h1>{this.props.text ? this.props.text : `Page Not Found`}</h1>
          <Button
            className="HomeExperience--product-cta"
            color="white"
            hasBorder
            isTransparent
            to="/"
          >
            Make it Better
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFoundView;
