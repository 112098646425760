import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import NotFoundView from '../components/NotFoundView';

import '../styles/global.scss';

const NotFoundPage = () => (
  <Layout view="404">
    <Seo title="404: Not found" />
    <NotFoundView />
  </Layout>
);

export default NotFoundPage;
